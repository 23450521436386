// @flow strict

/*
 * window[GLOBAL_JS_NAMESPACE].DBDefs contains the values exported by
 * DBDefs-client.mjs.
 * See root/layout/components/globalsScript.mjs for more info.
 *
 * This file should not be imported directly. It's substituted for
 * ./DBDefs-client.mjs via the NormalModuleReplacementPlugin in
 * webpack/browserConfig.js.
 */

const DBDefs = window[GLOBAL_JS_NAMESPACE].DBDefs;
export const DB_STAGING_TESTING_FEATURES = DBDefs.DB_STAGING_TESTING_FEATURES;
export const DEVELOPMENT_SERVER = DBDefs.DEVELOPMENT_SERVER;
export const DISABLE_IMAGE_EDITING = DBDefs.DISABLE_IMAGE_EDITING;
export const CRITIQUEBRAINZ_SERVER = DBDefs.CRITIQUEBRAINZ_SERVER;
export const GIT_BRANCH = DBDefs.GIT_BRANCH;
export const GIT_SHA = DBDefs.GIT_SHA;
export const MAPBOX_ACCESS_TOKEN = DBDefs.MAPBOX_ACCESS_TOKEN;
export const MAPBOX_MAP_ID = DBDefs.MAPBOX_MAP_ID;
export const SENTRY_DSN_PUBLIC = DBDefs.SENTRY_DSN_PUBLIC;
export const STATIC_RESOURCES_LOCATION = DBDefs.STATIC_RESOURCES_LOCATION;
export const WEB_SERVER = DBDefs.WEB_SERVER;
export const WIKITRANS_SERVER = DBDefs.WIKITRANS_SERVER;
export const MB_LANGUAGES = DBDefs.MB_LANGUAGES;
